declare let __MAPBOX_TOKEN__: string

import type { Feature, FeatureCollection, Geometry } from 'geojson';

import { Status, LocationType as Type, Geolocation, SpotEntity } from "shared-libs/generated/server-types/entity/yms/spotEntity"
import { Point, Polygon } from 'geojson';
import { addMetersToLngLat, lngLatToWorld } from 'viewport-mercator-project';
import { vec2 } from 'gl-matrix';
import React from "react";
import { Viewport } from 'react-map-gl';
import MapGL, { Source, Layer } from '@urbica/react-map-gl'
import { IBaseProps } from "../base-props";
import { Entity } from "shared-libs/models/entity";
import _ from 'lodash';
import { Button, Classes, Colors, Icon, InputGroup } from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';
import { Select } from '../select';
import classNames from 'classnames';
import { MapMouseEvent } from 'mapbox-gl';

const LEFT_MOUSE_BUTTON = 0
const RIGHT_MOUSE_BUTTON = 2

const SATELLITE_STYLE = 'mapbox://styles/mapbox/satellite-v9'
const LIGHT_STYLE = 'mapbox://styles/mapbox/light-v9'

const SPOT_WIDTH_KM: number = .003048; //10ft
const SPOT_LONG_LENGTH_KM: number = .02286; //55ft
const SPOT_SHORT_LENGTH_KM: number = .016764; //75ft

const SPOT_HALF_WIDTH_KM: number = SPOT_WIDTH_KM / 2;
const SPOT_HALF_LONG_LENGTH_KM: number = SPOT_LONG_LENGTH_KM / 2;
const SPOT_HALF_SHORT_LENGTH_KM: number = SPOT_SHORT_LENGTH_KM / 2;

/**
 * Offsets for a rectangle representing a truck parking space. The first and last points are the
 * same to close the polygon.
 */
const SPOT_RECT_METER_OFFSETS = [
  vec2.fromValues(-SPOT_HALF_WIDTH_KM * 1000, -SPOT_HALF_SHORT_LENGTH_KM * 1000),
  vec2.fromValues(-SPOT_HALF_WIDTH_KM * 1000,  SPOT_HALF_SHORT_LENGTH_KM * 1000),
  vec2.fromValues( SPOT_HALF_WIDTH_KM * 1000,  SPOT_HALF_SHORT_LENGTH_KM * 1000),
  vec2.fromValues( SPOT_HALF_WIDTH_KM * 1000, -SPOT_HALF_SHORT_LENGTH_KM * 1000),
  vec2.fromValues(-SPOT_HALF_WIDTH_KM * 1000, -SPOT_HALF_SHORT_LENGTH_KM * 1000),
]

/**
 * Determines what properties to generate for a Layer element rendering spots based on spot type.
 */
const LAYER_PROP_GENERATORS: Partial<Record<Type, (color: string) => any>> = {
  [Type.DOOR]: genPointLayerProperties,
  [Type.SPOT]: genRectangleLayerProperties,
}

/**
 * Determines how to convert a spot to a GeoJSON Feature based on spot type. The Feature will be
 * provided by a Source element to a Layer element for rendering.
 */
const GEOMETRY_GENERATORS = {
  [Type.DOOR]: genPointFeature,
  [Type.SPOT]: genRectangleFeature,
}

const enum Availability {
  DRAFT = "draft",
  DISABLED = "disabled",
  FULL = "full",
  PARTIAL = "partial",
  EMPTY = "empty",
}

const AVAILABILITY_COLORS = {
  [Availability.DRAFT]: Colors.CERULEAN1,
  [Availability.DISABLED]: '#808080',
  [Availability.FULL]: '#FF0000',
  [Availability.PARTIAL]: '#FFFF00',
  [Availability.EMPTY]: '#00FF00',
}

const STATUS_AVAILABILITY_MAP = {
  [Status.FULL]: Availability.FULL,
  [Status.PARTIAL]: Availability.PARTIAL,
  [Status.OPEN]: Availability.EMPTY,
}

export interface IYardMapProps extends IBaseProps {
  entity: Entity
  placedSpots?: SpotEntity[]
  placeableSpots?: SpotEntity[]
  draftSpots: Entity[]
  selectedSpotId?: string
  handleSpotClicked?: (spotId: string) => void
  handleDraftSpotsAdded?: (geolocations: Geolocation[]) => void
}

type ColorMap = Partial<Record<Availability, string>>

export interface IYardMapState {
  style: string
  viewport: {
    latitude: number
    longitude: number
    zoom: number
  }
  hoverInfo: {
    lng: number
    lat: number
  }
  colors?: ColorMap
  spotPlanning?: SpotPlanningState
}

export interface SpotPlanningState {
  stage: 'none' | 'placement' | 'details'
  placeableSpot?: SpotEntity
  type?: Type
  rayOrigin?: LatLong
  rayHeading?: number
  rayTarget?: LatLong
  spacingMeters?: number
  numSpots?: number
  groupName?: string
  spotHeading?: number
  spotGeolocations?: Geolocation[]
}

type LatLong = Required<Pick<Geolocation, 'latitude' | 'longitude'>>

type FeatureProperties = Geolocation & {
  name: string
  spotId: string
  currentTrailers: Entity[]
  textHeadingDegrees: number
}

export class YardMap extends React.Component<IYardMapProps, IYardMapState> {

  constructor(props) {
    super(props);

    const { entity } = this.props
    const providedColors = entity.get('core_yms_yardView.colors')
    const geolocation: Geolocation = _.get(entity, [
      'core_yms_yardView',
      'facility',
      'denormalizedProperties',
      'location.address',
      'geolocation',
    ])

    this.state = {
      style: LIGHT_STYLE,
      spotPlanning: {
        stage: 'none'
      },
      viewport: {
        latitude: geolocation.latitude,
        longitude: geolocation.longitude,
        zoom: 16
      },
      hoverInfo: null,
      colors: providedColors
    }
  }

  private onMouseClick = (event) => {
    const { lngLat } = event;
    this.setState({
      hoverInfo: lngLat
    })
  }

  private handleFeatureClick = (event) => {
    const { handleSpotClicked } = this.props
    if (!handleSpotClicked) {
      return;
    }

    if (!event.features || event.features.length == 0) {
      handleSpotClicked(undefined)
      return
    }

    const feature: Feature<Geometry, FeatureProperties> = event.features[0]
    const { properties } = feature
    const { spotId } = properties

    handleSpotClicked(spotId)
  }

  public render() {
    const { placedSpots, draftSpots } = this.props
    const { style, viewport, hoverInfo, colors, spotPlanning } = this.state;
    const spotsByType = _.groupBy(placedSpots, getType);

    const spotLayers: JSX.Element[][] = Object.entries(spotsByType)
      .map(([type, spots]) => this.renderSpots(type as Type, spots, colors));
    const draftLayers = this.renderDraftSpots(draftSpots, colors)

    const flattenedLayers: JSX.Element[] = _.flatMap(_.concat(spotLayers, draftLayers));

    return (
      <MapGL
        className="w-100 h-100"
        {...viewport}
        mapStyle={style}
        accessToken={__MAPBOX_TOKEN__}
        onViewportChange={(viewport: Viewport) => this.setState({ viewport })}
        onMousedown={this.onMouseClick}
        onMousemove={this.handleMouseMoved}
        onClick={this.handleMouseClicked}
      >
        {flattenedLayers}
        {spotPlanning && this.renderSpotPlanning()}
        {this.renderButtonRow()}
      </MapGL>
    )
  }

  private renderButtonRow = () => {
    const { spotPlanning } = this.state
    return (
      <div className={classNames('map-interface-button-row')}>
        {this.renderStyleToggle()}
        {this.renderSpotSelectDropdown()}
        {this.renderAddSpotButton()}
        {spotPlanning.stage === 'details' && this.renderSpotPlanningDialog()}
      </div>
    );
  }

  private renderStyleToggle = () => {
    return (
      <Button
        className={classNames('map-interface-button', 'paper', Classes.BUTTON)}
        onClick={this.handleStyleToggle}
      >
        <Icon icon={IconNames.SATELLITE} />
      </Button>
    )
  }

  private handleStyleToggle = () => {
    this.setState((prevState) => ({
      style: prevState.style === SATELLITE_STYLE ? LIGHT_STYLE : SATELLITE_STYLE
    }))
  }

  private renderAddSpotButton = () => {
    return (
      <Button
        className={classNames('map-interface-button', Classes.INTENT_PRIMARY, Classes.BUTTON)}
        onClick={this.handleAddSpotClicked}
      >
        <Icon icon={IconNames.INSERT} />
      </Button>
    )
  }

  private renderSpotSelectDropdown() {
    const { placeableSpots } = this.props

    if (!placeableSpots || placeableSpots.length == 0) {
      return null
    }

    return (
      <Select
        style={{ pointerEvents: 'auto' }}
        clearable={true}
        isStatic={true}
        inputClassName='map-interface-text-input'
        options={placeableSpots}
        optionValuePath={'uniqueId'}
        optionLabelPath={'core_yms_spot.name'}
        optionGroupPath={'core_yms_spot.area'}
        onChange={this.handlePlaceableSpotSelected}
        value={null}
        placeholder={'Search placeable spots...'}
        searchPromptText='Type to search...'
        noOptionsMessage='No placeable spots'
        searchable={true}
      />
    )
  }

  private handlePlaceableSpotSelected = (spotId: string, spot: SpotEntity) => {
    console.log(spot)
    this.setState((prevState) => {
      const { spotPlanning } = prevState
      const { stage } = spotPlanning
      return {
        spotPlanning: {
          stage: spot ? 'placement' : 'none',
          placeableSpot: spot,
          numSpots: spot ? 1 : 0
        }
      }
    })
  }

  private handleAddSpotClicked = () => {
    this.setState((prevState) => {
      const { spotPlanning } = prevState
      const { stage } = spotPlanning
      const updatedState = {
        stage: stage === 'none' ? 'placement' : 'none' as 'none' | 'placement',
        numSpots: stage === 'none' ? 1 : 0,
      }
      return {
        spotPlanning: {
          ...updatedState,
        }
      }
    })
  }

  private renderSpotPlanningDialog = () => {
    const { spotPlanning } = this.state
    const { rayHeading, numSpots, spacingMeters, spotHeading } = spotPlanning
    return (
      <div style={{ padding: '20px', pointerEvents: 'auto' }}>
        <InputGroup
          placeholder="Row Heading"
          value={rayHeading !== undefined ? rayHeading.toString() : ''}
          onChange={(e) => this.handleInputSave({rayHeading: e.target.value !== '' ? parseFloat(e.target.value) : 0})}
        />
        <InputGroup
          placeholder="Number of Spots"
          value={numSpots !== undefined ? numSpots.toString() : ''}
          onChange={(e) => this.handleInputSave({numSpots: e.target.value !== '' ? parseFloat(e.target.value) : 1})}
        />
        <InputGroup
          placeholder="Spacing Meters"
          value={spacingMeters !== undefined ? spacingMeters.toString() : ''}
          onChange={(e) => this.handleInputSave({spacingMeters: e.target.value !== '' ? parseFloat(e.target.value) : 0})}
        />
        <InputGroup
          placeholder="Spot Heading"
          value={spotHeading !== undefined ? spotHeading.toString() : ''}
          onChange={(e) => this.handleInputSave({spotHeading: e.target.value !== '' ? parseFloat(e.target.value) : 0})}
        />
        <Button
          onClick={this.handleSavePlaceholderSpots}
          style={{ marginTop: '20px' }}
          intent="primary"
        >
          Save
        </Button>
      </div>
    )
  }

  /**
   * Commit changes to the layout details to the spot planning state object, and recalculate laid
   * out geolocations.
   * @param newPlanningState
   */
  private handleInputSave = (newPlanningState: Partial<SpotPlanningState>) => {
    this.setState((prevState) => {
      const spotGeolocations = this.genSpacedGeolocations({
        ...prevState.spotPlanning,
        ...newPlanningState,
      })
      return {
        spotPlanning: {
          ...prevState.spotPlanning,
          ...newPlanningState,
          spotGeolocations,
        }
      }
    })
  };

  /**
   * Saves all current spot placeholders as draft spots.
   */
  private handleSavePlaceholderSpots = () => {
    const { handleDraftSpotsAdded } = this.props
    const { spotGeolocations } = this.state.spotPlanning
    this.setState({ spotPlanning: { stage: "none" }})
    if (handleDraftSpotsAdded) {
      handleDraftSpotsAdded(spotGeolocations)
    }
  }

  /**
   * Generates an array of spot origins (represented by Geolocations), that reflect the offsets and
   * headings provided by the user in layout details.
   */
  private genSpacedGeolocations = (spotPlanning: SpotPlanningState): Geolocation[] => {
    const {rayOrigin: origin, numSpots, spotHeading: spotHeadingDegrees } = spotPlanning
    if (!origin) {
      return [];
    }

    const spotOffsetAlongRayMeters = this.calcSpotOffsetAlongRayMeters(spotPlanning)
    return Array.from({ length: numSpots }, (_, index) => index)
      .map(spotIndex => {
        const spotOffsetMeters = vec2.create()
        vec2.scale(spotOffsetMeters, spotOffsetAlongRayMeters, spotIndex)
        const spotVec = addMetersToLngLat([origin.longitude, origin.latitude], [spotOffsetMeters[0], spotOffsetMeters[1]])
        const [longitude, latitude] = spotVec
        return {
          latitude: latitude,
          longitude: longitude,
          heading: spotHeadingDegrees
        }
      })
  }

  /**
   * Calculate the distance between spot origins (at spot center) given the current spot planning
   * layout parameters. This offset is used to lay out mutiple spot placeholders with constant
   * spacing along a straight line.
   * 
   * If the user is not in details mode, simply return an offset of [0, 0] since user only works
   * with one placeholder in placement mode.
   * @param spotPlanning 
   * @returns 
   */
  private calcSpotOffsetAlongRayMeters = (spotPlanning: SpotPlanningState): vec2 => {
    if (spotPlanning.stage === 'placement') {
      return vec2.fromValues(0, 0)
    }

    const { rayOrigin: origin, rayHeading: rayHeadingDegrees = 0, rayTarget, spacingMeters = 0, spotHeading: spotHeadingDegrees = 0 } = spotPlanning

    const originMeters = lngLatToWorld([origin.longitude, origin.latitude])

    const spotHeadingRadians = spotHeadingDegrees * (Math.PI / 180)
    let rayHeadingRadians
    if (rayTarget) {
      const rayTargetMeters = lngLatToWorld([rayTarget.longitude, rayTarget.latitude])
      const rayVectorMeters = vec2.create()
      vec2.subtract(rayVectorMeters, rayTargetMeters, originMeters)
      rayHeadingRadians = vec2.angle(vec2.fromValues(1, 0), rayVectorMeters)
      if (rayVectorMeters[1] < 0) {
        rayHeadingRadians = (2 * Math.PI) - rayHeadingRadians
      }
    } else {
      rayHeadingRadians = rayHeadingDegrees * (Math.PI / 180)
    }

    // Normalize the spot heading to make ray intersection calculation easier.
    //
    // Any rotations need to be accounted for when measuring spacing. In this situation the row of
    // spots is rotated, and the spots themselves are rotated relative to the spot row. By
    // subtracting the spot heading from the row heading, we can pretend spots are axis aligned and
    // only the row rotation needs to be taken into account when measuring offsets.
    const measureHeadingRadians = rayHeadingRadians - spotHeadingRadians

    // Derive the unit vector along the measuring line. This represents a single unit of distance
    // along the normalized measuring line. Since we're working with rectangles defined in KM, this
    // vector is a single KM of distance along the measuring line.
    const measureUnitX = Math.cos(measureHeadingRadians)
    const measureUnitY = Math.sin(measureHeadingRadians)

    // Find which of the four edges of the spot rectangle the measuring line intersects with.
    let tMin = Infinity
    let intersection: vec2;

    [-SPOT_HALF_WIDTH_KM , SPOT_HALF_WIDTH_KM].forEach(x => {
      const t = x / measureUnitX
      if (t > 0) {
        const y = t * measureUnitY
        if (-SPOT_HALF_SHORT_LENGTH_KM <= y && y <= SPOT_HALF_SHORT_LENGTH_KM) {
          if (t < tMin) {
            tMin = t
            intersection = vec2.fromValues(x, y)
          }
        }
      }
    });

    [-SPOT_HALF_SHORT_LENGTH_KM, SPOT_HALF_SHORT_LENGTH_KM].forEach(y => {
      const t = y / measureUnitY
      if (t > 0) {
        const x = t * measureUnitX
        if (-SPOT_HALF_WIDTH_KM <= x && x <= SPOT_HALF_WIDTH_KM) {
          if (t < tMin) {
            tMin = t
            intersection = vec2.fromValues(x, y)
          }
        }
      }
    })

    // Find the distance from the spot origin, to the spot edge where the measuring line intersects
    const spotCenterToEdgeAlongRayKilometers = vec2.distance(vec2.fromValues(0, 0), intersection)
    // Calculate the scalar offset, in meters, from one spot origin to the next spot origin. It is
    // the distance from the first spot's origin to the the first spot's edge, then from the first
    // spot's edge to the next spot's edge, and finally from the next spot's edge to the next spot's
    // origin.
    const spotSpacingAlongRayMeters = (2 * 1000 * spotCenterToEdgeAlongRayKilometers) + spacingMeters

    // Calculate the unit vector along the spot row line.
    const rayUnitX = Math.cos(rayHeadingRadians)
    const rayUnitY = Math.sin(rayHeadingRadians)

    // By applying the scalar offset to the spot row's unit vector, we achieve the offset vector,
    // in meters, of spots along the spot row line
    return vec2.fromValues(rayUnitX * spotSpacingAlongRayMeters, rayUnitY * spotSpacingAlongRayMeters)
  }

  /**
   * Converts spots of a specified type to a list of elements renderable by a parent MapGL element.
   * Each spot is rendered based on its type and its availability.
   *
   * Doors are rendered as points, and spots are rendered as rectangles. This association is
   * encoded in LAYER_PROP_GENERATORS and GEOMETRY_GENERATORS.
   *
   * Availability determines the spot's color, mapped in AVAILABILITY_COLORS.
   *
   * Each unique combination of spot type and availability requires its own Layer which is created
   * with render properties specific to that combination. Each Layer in turn requires its own Source
   * to provide the spots as GeoJSON features for rendering.
   * @param type the spot type to render
   * @param spots the spots to render
   * @returns list of Layers and their respective Sources for rendering spots of this type
   */
  private renderSpots = (type: Type, spots?: SpotEntity[], colors?: ColorMap) => {
    if (!spots || spots.length == 0) {
      return null;
    }

    const spotsByAvailability = _.groupBy(spots, getAvailability);
    const layers = Object.entries(spotsByAvailability)
      .reduce((layers, [availability, spots]) => {
        console.log(`Rendering ${spots.length} ${availability} ${type}${spots.length > 1 ? 's' : ''}.`)
        layers.push(
          this.genSource(type, availability as Availability, spots),
          this.genShapeLayer(type, availability as Availability, colors),
          genTextLayer(type, availability as Availability));
        return layers;
      },
      []);

    console.log(type, layers);

    return layers;
  }

  /**
   * Render the draft spots. Draft spot availability cannot be derived from the entity itself so
   * draft rendering is split from renderSpots here.
   */
  private renderDraftSpots = (spots?: Entity[], colors?: ColorMap) => {
    if (!spots || spots.length == 0) {
      return null
    }

    return [
      this.genSource(Type.SPOT, Availability.DRAFT, spots),
      this.genShapeLayer(Type.SPOT, Availability.DRAFT, colors),
      genTextLayer(Type.SPOT, Availability.DRAFT)
    ];
  }

  /**
   * Generates a Layer element to render a set of spots. The spots are represented by a
   * FeatureCollection stored in a sibling Source element with the same id.
   * @param type the layer's spot type
   * @param availability the layer's spot availability
   * @returns the Layer that renders spots of this type and availability
   */
  private genShapeLayer = (type: Type, availability: Availability, colors?: ColorMap): JSX.Element => {
    const id = genId(type, availability);
    const color = {...AVAILABILITY_COLORS, ...colors}[availability];
    const layerProperties = LAYER_PROP_GENERATORS[type](color);

    return <Layer
      key={`${id}-shapes`}
      id={`${id}-shapes`}
      source={id}
      onClick={this.handleFeatureClick}
      {...layerProperties}
    />
  }

  private renderSpotPlanning = (): JSX.Element[] => {
    const { spotPlanning } = this.state
    const { spotGeolocations = [], numSpots = 0, spacingMeters = 0 } = spotPlanning
    if (spotGeolocations.length === 0
      || numSpots <= 0
      || spacingMeters < 0
    ) {
      return null
    }

    return this.genSpotPlaceholders(spotPlanning)
  }

  private genSpotPlaceholders = (spotPlanning: SpotPlanningState): JSX.Element[] => {
    return [
      <Source
        key='spotPlaceholdersSource'
        id='spotPlaceholdersSource'
        type="geojson"
        data={this.genSpacedSpotFeatureCollection(spotPlanning)}
      />,
      <Layer
        key='spotPlaceholdersLayer'
        id='spotPlaceholdersLayer'
        source='spotPlaceholdersSource'
        type="fill"
        paint={{
          'fill-color': Colors.CERULEAN5,
          'fill-outline-color': '#FFF'
        }}
      />
    ]
  }

  private genSpacedSpotFeatureCollection = (spotPlanning: SpotPlanningState) => {
    const { spotGeolocations} = spotPlanning

    const features = spotGeolocations.map((geolocation) => {
      return {
        type: 'Feature',
        properties: {
          ...geolocation
        },
        geometry: genPolygon(geolocation, SPOT_RECT_METER_OFFSETS)
      }
    })

    return {
      type: "FeatureCollection",
      features: features
    }
  }

  /**
   * Keep the spot planning origin position up to date with the mouse cursor as it moves.
   * @param event mouse move event
   */
  private handleMouseMoved = (event: MapMouseEvent) => {
    const { lng: longitude, lat: latitude } = event.lngLat
    this.setState((prevState) => {
      const { spotPlanning: prevSpotPlanning } = prevState
      const { stage = 'none' } = prevSpotPlanning
      if (stage === 'placement') {
        const mouseLocation = { latitude: latitude, longitude: longitude }
        const freshGeolocations = this.genSpacedGeolocations({
            ...prevSpotPlanning,
            rayOrigin: mouseLocation,
          })
        return {
          spotPlanning: {
            ...prevSpotPlanning,
            rayOrigin: mouseLocation,
            spotGeolocations: freshGeolocations,
          }
        }
      }
    })
  }

  /**
   * If in spot placement mode, advances to layout details mode where user is able to lay out
   * multiple spots at once.
   * @param event mouse click event
   */
  private handleMouseClicked = (event: MapMouseEvent) => {
    if (event.originalEvent.button != LEFT_MOUSE_BUTTON) {
      return
    }

    const { spotPlanning } = this.state
    const { stage = 'none', placeableSpot, spotGeolocations } = spotPlanning
    if (stage != 'placement') {
      return
    }

    if (placeableSpot) {
      placeableSpot.core_yms_spot.geolocation = spotGeolocations[0]
      const entity = placeableSpot as unknown as Entity
      entity.save();
    }

    this.setState((prevState) => {
      if (prevState?.spotPlanning?.placeableSpot) {
        return {
          spotPlanning: {
            stage: 'none',
            placeableSpot: undefined,
            numSpots: 0
          }
        }
      } else {
        return {
          spotPlanning: {
            ...prevState.spotPlanning,
            stage: 'details',
            spotGeolocations: this.genSpacedGeolocations(prevState.spotPlanning)
          }
        }
      }
    })
  }

  /**
   * Generates a Source element to use as a data set of GeoJSON features.
   * @param type the spot type to generate the Source element for
   * @param availability the availability of the spots
   * @param spots the generated Source's spot data
   * @returns a Source element providing the spots as GeoJSON features
   */
  private genSource = (type: Type, availability: Availability, spots: (SpotEntity | Entity)[]): JSX.Element => {
    const id = genId(type, availability);

    return <Source
      key={`${id}-source`}
      id={id}
      type="geojson"
      data={genFeatureCollection(type, spots)}
    />
  }
}

/**
 * Generates a Layer element to render spot labels. The spots are represented by a FeatureCollection
 * stored in a sibling Source element with the same id.
 * @param type the layer's spot type
 * @param availability the layer's spot availability
 * @returns the Layer that renders text labels for spots of this type and availability
 */
function genTextLayer(type: Type, availability: Availability): JSX.Element {
  const id = genId(type, availability);

  return <Layer
    key={`${id}-labels`}
    type="symbol"
    id={`${id}-labels`}
    source={id}
    layout={{
      'text-field': ['get', 'name'],
      'text-font': ['Open Sans Regular'],
      'text-size': {
        stops: [
          [16.5, 3],
          [18, 10],
          [20, 18]
        ],
        base: 1,
      },
      'text-allow-overlap': false,
      'symbol-spacing': 1,
      'text-padding': 0,
      'icon-pitch-alignment': 'map',
      'text-pitch-alignment': 'map',
      'text-rotation-alignment': 'map',
      ...genTextLayerProps(type)
    }}
    paint={{
      'text-color': '#000000'
    }}
  />
}

function genTextLayerProps(type: Type): Record<string, any> {
  switch (type) {
    case Type.SPOT:
      return {
        'text-anchor': 'center',
        'text-rotate': ['get', 'textHeadingDegrees'],
      }
    case Type.DOOR:
      return {
        'text-offset': [.5, .25],
        'text-anchor': 'bottom-left',
        'text-rotate': 315,
      }
  }
}



/**
 * Converts a set of spots to GeoJSON features appropriate to the spot type. Doors are converted to
 * points, and spots are converted to rectangles. This collection will be used as the data set for
 * the Source element, that in turn provides the features for rendering by a sibling Layer element
 * with the same id.
 * @param type the spot type to generate the collection for.
 * @param spots the spots to convert to features
 * @returns a feature collection to be added to a Source element
 */
function genFeatureCollection<G extends Geometry>(
  type: Type,
  spots: (SpotEntity | Entity)[]
): FeatureCollection<G> {
  const features: Feature<G>[] = spots.map((spot) => {
    const { latitude, longitude, heading: headingDegrees = 0 } = getGeolocation(spot)
    const spotHeadingDegrees = normalizeAngleDegrees(headingDegrees)
    let textHeadingDegrees = spotHeadingDegrees - 90
    while (textHeadingDegrees >= 180) {
      textHeadingDegrees -= 180
    }

    //TODO fix this geolocation enumeration
    return {
      type: 'Feature',
      properties: {
        name: getName(spot),
        latitude: latitude,
        longitude: longitude,
        spotId: spot.uniqueId,
        currentTrailers: getCurrentTrailers(spot),
        textHeadingDegrees: textHeadingDegrees,
      },
      geometry: GEOMETRY_GENERATORS[type](spot)
    }
  });

  return {
    type: "FeatureCollection",
    features: features
  }
}

/**
 * Generate a GeoJSON polygon feature that represents a rectangle from a spot.
 * @param spot the spot
 * @returns the GeoJSON polygon feature
 */
function genRectangleFeature(spot: SpotEntity): Polygon {
  return genSpotPolygon(spot, SPOT_RECT_METER_OFFSETS)
}

function genSpotPolygon(spot: SpotEntity, offsets: vec2[]): Polygon {
  return genPolygon(getGeolocation(spot), offsets)
}

function genPolygon(geolocation: Geolocation, offsets: vec2[]): Polygon {
  const { longitude, latitude, heading: headingDegrees = 0 } = geolocation

  const headingDegreesNormalized = normalizeAngleDegrees(headingDegrees);

  if (headingDegreesNormalized != 0) {
    const headingRadians = (headingDegreesNormalized * Math.PI) / 180;
    offsets = offsets.map((offset) => {
      const rotated = vec2.create();
      vec2.rotate(rotated, offset, [0, 0], headingRadians);
      return rotated;
    });
  }

  const coordinates = offsets.map((offset: [number, number]) => {
    return addMetersToLngLat([longitude, latitude], offset);
  });

  return {
    type: 'Polygon',
    coordinates: [coordinates]
  };
}

/**
 * Normalizes an angle to an amount of degrees between 0 inclusive and 360 exclusive.
 * @param angle any amount of degrees
 * @returns an angle between 0 and 360 degrees that is rotationally equivalent to the input angle
 */
function normalizeAngleDegrees(angle: number): number {
  const normalized = angle % 360;
  return normalized > 0 ? normalized : normalized + 360;
}

/**
 * Generate the GeoJSON feature that represents a point from a spot.
 * @param spot the spot
 * @returns the GeoJSON point feature
 */
function genPointFeature(spot: SpotEntity): Point {
  const { longitude, latitude } = getGeolocation(spot);
  return {
    type: 'Point',
    coordinates: [longitude, latitude]
  }
}

/**
 * Generates the Layer properties for a layer that renders points.
 * @param color the point color
 * @returns the point layer properties
 */
function genPointLayerProperties(color: string) {
  return {
    type: "circle",
    paint: {
      'circle-color': color,
      'circle-radius': 6,
      'circle-stroke-color': '#FFF'
    }
  }
}

/**
 * Generates the Layer properties for a layer that renders filled polygons.
 * @param color the rectangle color
 * @returns the rectangle layer properties
 */
function genRectangleLayerProperties(color: string) {
  return {
    type: "fill",
    paint: {
      'fill-color': color,
      'fill-outline-color': '#FFF'
    }
  }
}

/**
 * Generates an id for an element based on the type and availability of the spots the element is
 * rendering.
 */
function genId(type: Type, availability: Availability): string {
  return `${type}-${availability}`;
}

/**
 * Get the availability state of the spot.
 */
function getAvailability(spot: SpotEntity): Availability {
  if (!isActive(spot)) {
    return Availability.DISABLED;
  }

  return STATUS_AVAILABILITY_MAP[getStatus(spot)]
}

function getCurrentTrailers(spot: Entity | SpotEntity): Entity[] {
  return _.get(spot, ['core_yms_spot', 'currentTrailers'])
}

function getType(spot: SpotEntity): Type {
  return _.get(spot, ['core_yms_spot', 'locationType'])
}

function getStatus(spot: SpotEntity): Status {
  return _.get(spot, ['core_yms_spot', 'status'])
}

function getName(spot: Entity | SpotEntity): string {
  return _.get(spot, ['core_yms_spot', 'name'])
}

function getGroup(spot: Entity): string {
  return _.get(spot, ['core_yms_spot', 'group'])
}

function getGeolocation(spot: Entity | SpotEntity): Geolocation {
  return _.get(spot, ['core_yms_spot', 'geolocation'])
}

function isActive(spot: SpotEntity): boolean {
  return _.get(spot, ['core_yms_spot', 'isActive'])
}
