import React from "react";
import { v4 as uuidv4 } from 'uuid';
import { Entity } from "shared-libs/models/entity";
import apis from "browser/app/models/apis";
import { SchemaUris } from "shared-libs/models/schema";
import { EntityDataSource } from "../../organisms/entity/entity-data-source";
import _ from "lodash";
import { SpotEntity, Geolocation, Status, LocationType } from "shared-libs/generated/server-types/entity/yms/spotEntity";
import { EntityPage } from "browser/app/pages/app/entity/entity-page";
import { IBaseProps } from "../../atoms/base-props";
import { YardMap } from "../../atoms/mapbox/yard-map";
import { AutofillBlock } from "../../atoms/autofill-block/autofill-block";
import { MasterDetail } from "../../atoms/master-detail/master-detail";

/**
 * Results from EntityDataSource come back to us with the form
 * {
 *   data: data for this result
 *   children: children of this result. children of results are themselves results
 *   metadata: ???
 * }
 *
 * Since we're asking for grouped results, the top level of results will be the spot groups, and the
 * children results are the spots. The data field of the child results contains the spot entity.
 */
type QueryResult<T extends any = any, C extends any = any> = {data: T; children: QueryResult<C>[]}
type SpotResult = QueryResult<SpotEntity>

export interface IYardViewerProps extends IBaseProps {
  entity: Entity
}

export interface IYardViewerState {
  placedSpots: SpotEntity[]
  placeableSpots: SpotEntity[]
  draftSpots: Entity[]
  selectedSpotId?: string
}

export class YardViewer extends React.Component<IYardViewerProps, IYardViewerState> {

  private entityDataSet: EntityDataSource

  constructor(props: IYardViewerProps) {
    super(props)

    const { entity } = this.props
    const facilityId = entity.get('core_yms_yardView.facility.entityId')
    this.loadSpots(facilityId);

    this.state = {
      placedSpots: [],
      placeableSpots: [],
      draftSpots: [],
    }
  }

  private loadSpots = async (facilityUuid: string) => {
    const filters = [
      {
        path: 'core_yms_spot.facility',
        type: 'matchEdge',
        value: {
          entityId: facilityUuid,
        },
      }
    ]

    this.entityDataSet = new EntityDataSource({
      entityType: SchemaUris.SPOT_TYPE,
      filters: filters,
      metadata: {
        size: 999,
        maxSizePerGroup: 99,
      },
      refreshInterval: 5000,
      enabledPrefetch: true
    }).setOnChange(this.handleOnDataSetChange)
  }

  /**
   * Filter spot query results to placed spots, and clean up any draft spots that were just placed.
   */
  private handleOnDataSetChange = (queryResults: SpotResult[]) => {
    const spots = queryResults.map(spotResult => spotResult.data)
    const [placedSpots, placeableSpots] = _.partition(spots, spot => Boolean(getGeolocation(spot)))

    this.setState((prevState) => {
      const filteredDrafts = prevState.draftSpots.filter((draft) => {
        return !placedSpots.some(placed => placed.uniqueId === draft.uniqueId)
      })

      return {
        draftSpots: filteredDrafts,
        placedSpots,
        placeableSpots
      }
    });
  }

  private handleDraftSpotsAdded = (geolocations: Geolocation[]) => {
    const { entity } = this.props
    const facilityId = entity.get('core_yms_yardView.facility.entityId')
    const facilityDisplayName = entity.get('core_yms_yardView.facility.displayName')
    const facilityOwnerFirmId = entity.get(['core_yms_yardView', 'facility', 'denormalizedProperties', 'owner.firm', 'entityId'])
    const facilityOwnerDisplayName = entity.get(['core_yms_yardView', 'facility', 'denormalizedProperties', 'owner.firm', 'displayName'])

    const draftSpots: Entity[] = geolocations.map((geolocation, index) => {
      const data: SpotEntity = {
        status: {
          state: 'new',
        },
        core_yms_spot: {
          geolocation: geolocation,
          name: `Draft_${index}`,
          status: Status.OPEN,
          facility: {
            entityId: facilityId,
            displayName: facilityDisplayName,
          },
          capacity: 1,
          locationType: LocationType.SPOT,
        },
        uniqueId: uuidv4(),
        owner: {
          firm: {
            entityId: facilityOwnerFirmId,
            displayName: facilityOwnerDisplayName,
          }
        },
        mixins: {
          active: [
            {
              entityId: "11111111-0000-0000-0000-000000000000",
              displayName: "Entity",
            },
            {
              entityId: "5eb1f5f6-8ff4-458c-8119-39eee0f2f288",
              displayName: "Place",
            },
            {
              entityId: "5ec7171f-dd67-4580-af43-cbb7b7458723",
              displayName: "Spot",
            }
          ]
        }
      }
      return new Entity(data, apis)
    })

    this.setState((prevState) => {
      return {
        draftSpots: _.concat(draftSpots, prevState.draftSpots)
      }
    })
  }

  public componentDidMount() {
    this.entityDataSet.find()
  }

  public componentWillUnmount() {
    this.entityDataSet.dispose()
  }

  public render() {
    const { selectedSpotId } = this.state;
    const hasSelection = Boolean(selectedSpotId)

    return (
      <MasterDetail
        masterPanel={this.renderMapPanel()}
        detailPanel={this.renderDetailPanel()}
        detailPanelAllowResize={hasSelection}
        detailPanelSplit="vertical"
        showDetailPanel={hasSelection}
        isStackLayout={false}
      />
    )
  }

  private renderMapPanel = (): JSX.Element  => {
    const { entity } = this.props
    const { placedSpots, placeableSpots, selectedSpotId, draftSpots } = this.state;

    return (
      <div className="c-map-container">
        <AutofillBlock>
          <YardMap
            entity={entity}
            placedSpots={placedSpots}
            placeableSpots={placeableSpots}
            draftSpots={draftSpots}
            handleSpotClicked={this.handleSpotSelected}
            handleDraftSpotsAdded={this.handleDraftSpotsAdded}
            selectedSpotId={selectedSpotId}
          />
        </AutofillBlock>
      </div>
    )
  }

  private renderDetailPanel = (): JSX.Element => {
    const { placedSpots, selectedSpotId, draftSpots } = this.state;
    const hasSelection = Boolean(selectedSpotId)

    if (!hasSelection) {
      return <div></div>
    }

    let selectedSpot: (SpotEntity | Entity) = selectedSpotId && placedSpots.find((spot) => spot.uniqueId === selectedSpotId)
    if (!selectedSpot) {
      selectedSpot =  draftSpots.find((spot) => spot.uniqueId === selectedSpotId)
    }

    return (
      <EntityPage
        isFullScreen={false}
        entity={selectedSpot}
        history={history}
        location={location}
        onClose={() => this.handleSpotSelected(undefined)}
      />
    )
  }

  private handleSpotSelected = (selectedSpotId: string): void => {
    this.setState((prevState) => {
      const didSelectSameSpot = prevState.selectedSpotId === selectedSpotId
      return {
        selectedSpotId: didSelectSameSpot ? undefined : selectedSpotId
      }
    })
  }
}

function getGeolocation(spot: SpotEntity): Geolocation {
  return _.get(spot, ['core_yms_spot', 'geolocation'])
}
